body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  text-align: center;
  color: white;
  background-color: #282c34;
  animation: 2s fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

header>* {
  letter-spacing: normal;
}

a {
  color: beige;
  text-decoration: none;
}

header {
  letter-spacing: 5px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(56px + 2vmin);
}

sub {
  font-size: calc(24px + 2vmin);
  font-weight: lighter;
}

article {
  margin-top: 1.6ch;
  font-size: calc(9px + 2vmin);
  font-style: italic;
  margin-left: 15px;
  margin-right: 15px;
}

article.about {
  font-size: calc(8px + 2vmin);
  padding: 15px;
  border-radius: 15px;
  background-color: #3e4146;
  max-width: 650px;
}